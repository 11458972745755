// Catches click events and will only allow
// if confirm dialog isn't required
document.addEventListener('click', function(e) {
    $target = $(e.target);

    // console.log('click fired');

    if(!$target.hasClass('confirm-ignore') && ($target.attr('data-confirm') || $target.parents('[data-confirm]').length > 0)){
        console.log('confirm fired');

        // If an event is defined
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        $target = ($target.attr('data-confirm'))? $target : $target.parents('[data-confirm]');

        // Add temp class to target
        $target.addClass('confirm-temp');

        // -------------------------------
        // Build confirm dialog
        // -------------------------------
        var icon = ($target.data('confirm-icon'))? $target.data('confirm-icon') : 'circle-check';
        $confirm = $('<div />');
        $confirm.attr('id', 'confirm_dialog')
                .addClass('ui basic modal')
                .append('<div class="ui icon header"><i class="' + icon + ' icon"></i>Are you sure?</div>');
        $confirm_inner = $('<div />').addClass('content');

        // Add header + message/question
        if($target.attr('data-confirm')){
            $confirm_inner.append('<p style="text-align:center;">' + $target.attr('data-confirm') + '</p>');
        }

        // Add content to confirm
        $confirm.append($confirm_inner)
                .append('<div class="actions" style="text-align:center;"><div class="ui red basic cancel inverted button button-deny"><i class="remove icon"></i>No</div><div class="ui green ok inverted button button-approve"><i class="checkmark icon"></i>Yes</div></div>');

        // Append to body
        $('body').append($confirm);

        // Cache selectors
        $btn_approve = $confirm.find('.button-approve');
        $btn_deny    = $confirm.find('.button-deny');

        // Show modal
        $confirm.modal('show');

        // Remove modal function
        var removeConfirm = function(){
            $confirm.modal({
                onHidden: function(){
                    $('#confirm_dialog').remove();
                }
            })
            .modal('hide');
        };

        // Bind to confirm btn click
        $btn_approve.bind('click', function(e){
            e.preventDefault();

            // Get target using temp class
            $target = $('.confirm-temp');

            // Re trigger click
            // First check if we're simply loading a link
            if($target.attr('href') && $target.attr('href').substring(0,1) !== '#'){
                window.location = $target.attr('href');
            }

            // Otherwise re-trigger the click event
            var data = $target.data('confirm');
            $target.addClass('confirm-ignore')
                    .trigger('click')
                    .removeClass('confirm-ignore confirm-temp');

            // Hide confirm dialog
            removeConfirm();
        });

        // Bind to cancel btn click
        $btn_deny.bind('click', function(e){
            e.preventDefault();

            // Get target + remove temp class
            $('.confirm-temp').removeClass('confirm-temp');

            // Hide confirm dialog
            removeConfirm();
        });
    }
}, true);

// Useful function for using confirm in scripts
function confirmDialogue(callback, msg, icon){
    // Check we've got a callback
    if(!callback) return false;

    // -------------------------------
    // Build confirm dialog
    // -------------------------------
    var icon = (icon)? icon : 'circle-check';
    $confirm = $('<div />');
    $confirm.attr('id', 'confirm_dialog')
            .addClass('ui basic modal')
            .append('<div class="ui icon header"><i class="' + icon + ' icon"></i>Are you sure?</div>');
    $confirm_inner = $('<div />').addClass('content');

    // Add header + message/question
    if(msg) $confirm_inner.append('<p style="text-align:center;">' + msg + '</p>');

    // Add content to confirm
    $confirm.append($confirm_inner)
            .append('<div class="actions" style="text-align:center;"><div class="ui red basic cancel inverted button button-deny"><i class="remove icon"></i>No</div><div class="ui green ok inverted button button-approve"><i class="checkmark icon"></i>Yes</div></div>');

    // Append to body
    $('body').append($confirm);

    // Cache selectors
    $btn_approve = $confirm.find('.button-approve');
    $btn_deny    = $confirm.find('.button-deny');

    // Show modal
    $confirm.modal('show');

    // Remove modal function
    var removeConfirm = function(){
        $confirm.modal({
            onHidden: function(){
                $('#confirm_dialog').remove();
            }
        })
        .modal('hide');
    };

    // Bind to confirm btn click
    $btn_approve.bind('click', function(e){
        e.preventDefault();

        // Fire callback
        callback();

        // Hide confirm dialog
        removeConfirm();
    });

    // Bind to cancel btn click
    $btn_deny.bind('click', function(e){
        e.preventDefault();

        // Hide confirm dialog
        removeConfirm();
    });
}