/**
 * Used for simple ajax requests
 **/
function ajaxSimple(url, data, callback, method){
    var method = (method)? method : 'POST';
    var data   = (data)? data : null;

    $.ajax({
        type: method,
        url:  url,
        data: data,
        complete: function(data){
            if(callback) callback(data);
        }
    });
}

/*
 * Uppercase first letter in string
 */
String.prototype.ucfirst = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

/*
 * Array subarray slice function
 */
Array.prototype.subarray=function(start,end){
     if(!end){ end=-1;} 
    return this.slice(start, this.length+1-(end*-1));
}

/*
 * Clean string of html
 */
function stripHtml(str){
    return str.replace(/(<([^>]+)>)/ig,"");
}

/**
 * Limit length of a given string
 **/
function limitString(str, limit){
    var limit = (limit)? limit : 20;

    return (str.length > limit)? str.substring(0, limit) + '...' : str;
}

/**
 * Convert string to url friendly format
 **/
function toSlug(str){
    return str
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-');
}

/**
 * Format bytes into KB, MB etc
 **/
function formatBytes(bytes) {
    if (bytes == 0) return '0 B';

    var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    var precision;
    switch(sizes[i]){
        case 'B':
        case 'KB':
            precision = 0;
        break;
        case 'MB':
        case 'GB':
            precision = 1;
        break;
        default:
            precision = 2;
        break;
    }

    return (bytes / Math.pow(1024, i)).toFixed(precision) + ' ' + sizes[i];
};

/**
 * Used to toggle an elements visibility
 **/
$(document).on('click', '[data-toggle]', function(e){
    e.preventDefault();
    $($(this).data('toggle')).toggleClass('hide');
});

/**
 * Used to show an element
 **/
$(document).on('click', '[data-show]', function(e){
    e.preventDefault();
    $($(this).data('show')).show();
});

/**
 * Used to hide an element
 **/
$(document).on('click', '[data-hide]', function(e){
    $($(this).data('hide')).hide();
});

/*
 * Simulate a pure javascript event on any element
 */
function simulatedClick(target, options) {

    var event = target.ownerDocument.createEvent('MouseEvents'),
        options = options || {};

    //Set your default options to the right of ||
    var opts = {
        type: options.type                   || 'click',
        canBubble:options.canBubble          || true,
        cancelable:options.cancelable        || true,
        view:options.view                    || target.ownerDocument.defaultView,
        detail:options.detail                || 1,
        screenX:options.screenX              || 0, //The coordinates within the entire page
        screenY:options.screenY              || 0,
        clientX:options.clientX              || 0, //The coordinates within the viewport
        clientY:options.clientY              || 0,
        ctrlKey:options.ctrlKey              || false,
        altKey:options.altKey                || false,
        shiftKey:options.shiftKey            || false,
        metaKey:options.metaKey              || false, //I *think* 'meta' is 'Cmd/Apple' on Mac, and 'Windows key' on Win. Not sure, though!
        button:options.button                || 0, //0 = left, 1 = middle, 2 = right
        relatedTarget:options.relatedTarget  || null,
    }

    //Pass in the options
    event.initMouseEvent(
        opts.type,
        opts.canBubble,
        opts.cancelable,
        opts.view,
        opts.detail,
        opts.screenX,
        opts.screenY,
        opts.clientX,
        opts.clientY,
        opts.ctrlKey,
        opts.altKey,
        opts.shiftKey,
        opts.metaKey,
        opts.button,
        opts.relatedTarget
    );

    //Fire the event
    target.dispatchEvent(event);
}

/*
 * Get vendor prefix
 */
function getPrefix(key){
    var styles = window.getComputedStyle(document.documentElement, ''),
    pre = (Array.prototype.slice
        .call(styles)
        .join('') 
        .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
    )[1],
    dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];
    
    var prefixes = {
        dom: dom,
        lowercase: pre,
        css: '-' + pre + '-',
        js: pre[0].toUpperCase() + pre.substr(1)
    };

    return (key)? prefixes[key] : prefixes['js'];
}

/*
 * Delay an event firing ie. on keypress/up/down etc.
 */
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();