// ------------------------------------------------
// Simple css loader plugin
// ------------------------------------------------
$.fn.loader = function(action, text) {
    return this.each( function() {
        var $context = $(this);

	    // Init | Show
	 	if(!action){
			$inner = $('<div />').addClass('ui loader');
			if(text) $inner.addClass('text').html(text);

			$loader = $('<div />').addClass('ui active inverted dimmer _loader').append($inner);

			$context.append($loader);
		}

		// Hide | Destroy
		if(action == 'hide' || action == 'destroy') $context.find('._loader').remove();
	});
};

$.fn.success = function(action) {
    return this.each( function() {
        var $context = $(this);

	    // Init | Show
	 	if(!action){
			$success = $('<div />').addClass('ui active inverted dimmer _success')
								  .append(
								  	  $('<div />')
								  	  .addClass('content')
								  	  .append(
									  	  $('<div />')
									  	  .addClass('center')
									  	  .append(
										  	  $('<i />')
										  	  .addClass('huge green circular check alt icon')
										  )
									  )
								  );
			$context.append($success);
		}

		// Hide | Destroy
		if(action == 'hide' || action == 'destroy') $context.find('._success').remove();
	});
};