(function($) {

    // --------------------------------------
    //
    // File Uploader
    // 
    // --------------------------------------

    $.fn.uploader = function() {

        var uploaderInit = function() {
            console.log('Fired Uploaders');

            // ------------------------------------
            // Setup
            // ------------------------------------
            // Define main object
            var self = {
                $uploader: $(this)
            };

            // Create the file input
            self.$input = $('<input />')
                        .attr('type', 'file')
                        .attr('name', 'uploader_files');
            if(self.$uploader.data('type') !== 'single') self.$input.attr('multiple', 'multiple');
            
            // Append the file input
            self.$uploader.append( self.$input.addClass('hide') );

            // Get allowed files
            var allowed = (self.$uploader.data('allowed'))? self.$uploader.data('allowed').split('|') : ['jpg','gif','png'];

            console.log(allowed);


            // ------------------------------------
            // Cache selectors
            // ------------------------------------
            self.$fileInput  = self.$uploader.find('input');
            self.$fileSelect = self.$fileInput;
            self.$fileDrop   = (self.$uploader.find('.uploader-btn').length > 0)? self.$uploader.find('.uploader-btn') : self.$uploader;
            self.$files      = self.$uploader.find('.uploader-files');
            self.$template   = self.$uploader.find('.uploader-file-template');
            


            // ------------------------------------
            // Force file select dialog
            // ------------------------------------
            self.$fileDrop.click(function(e) {
                self.$fileInput[0].click();
            });



            // ------------------------------------
            // Bind to file input change event
            // ------------------------------------
            var fileChange = function(e){
                var files  = $(this)[0].files;

                // Check if files were selected
                if(files.length < 1) return false;

                // If we have file types limit, check files
                if(Object.prototype.toString.call(allowed) === '[object Array]' && allowed.length > 0){

                    // Loop through files and check for allowed types
                    for(var x = files.length - 1; x >= 0; x--){
                        var check = false;

                        var nameParts = files[x].name.split('.');
                        var ext = (Object.prototype.toString.call(nameParts) === '[object Array]')? nameParts[nameParts.length - 1] : '';

                        for(var i = allowed.length - 1; i >= 0; i--){
                            console.log(ext);

                            if(ext == allowed[i]){
                                check = true;
                                break;
                            }

                        }

                        // Check if we have allowed files
                        if(!check){
                            self.$uploader.removeClass('active');

                            notification('Sorry you can only upload ' + allowed.join(' or ') + ' files here.', 'error');

                            return false;
                        }
                    }
                }

                // Add dropped success class
                self.$uploader.removeClass('active').addClass('loading');

                // Send data
                submitFiles();
            }
            self.$fileInput.bind('change', fileChange);



            // ------------------------------------
            // Process drag/drop events
            // ------------------------------------
            if(self.$fileDrop.length > 0){
                var dragEvents = function(e) {
                    e.stopPropagation();
                    e.preventDefault();

                    switch(e.type){
                        case 'dragover':
                            // Add active class
                            self.$uploader.addClass('active');

                            // console.log('Over');
                        break;
                        case 'dragleave':
                            // Remove active class
                            self.$uploader.removeClass('active');

                            // console.log('Leave');
                        break;
                        case 'drop':
                            // Replace file input files with dropped files
                            self.$fileInput[0].files = e.target.files || e.dataTransfer.files;

                            // console.log('Drop');
                        break;
                    }
                }

                // Attach file drag/drop event listeners
                self.$fileDrop[0].addEventListener("dragover", dragEvents, false);
                self.$fileDrop[0].addEventListener("dragleave", dragEvents, false);
                self.$fileDrop[0].addEventListener("drop", dragEvents, false);
            }



            // ------------------------------------
            // Send the file data
            // ------------------------------------
            var reqArray = [];

            var submitFiles = function(){
                self.$uploader.trigger('upload-start');

                // Add class to uploader
                self.$uploader.addClass('loading');

                // Get the file array
                var files  = self.$fileInput[0].files;

                // Loop through files and upload each individually
                $.each(files, function(i, el) {
                    var data = new FormData();
                    data.append("file_upload", files[i]);

                    // Get file attributes
                    var file_id   = toSlug(files[i]['name']);
                    var file_name = files[i]['name'];
                    var file_size = formatBytes(files[i]['size']);

                    // Create a new xhr request
                    reqArray[file_id] = new XMLHttpRequest();
                    var route = (self.$uploader.data('route'))? self.$uploader.data('route') : '/documents/upload';
                    reqArray[file_id].open('POST', route, true);

                    // Fire file upload event
                    var file = {
                        id:      file_id,
                        name:    file_name,
                        size:    file_size,
                        request: reqArray[file_id]
                    };
                    self.$uploader.trigger('upload', file);


                    // Listener: Complete
                    reqArray[file_id].item = file_id;
                    reqArray[file_id].onload = function(e) {
                        console.log(this);
                        
                        if (this.status == 200) {
                            var resp = JSON.parse(this.response);

                            // Trigger upload success event
                            self.$uploader.trigger('upload-success', {
                                item:     this.item,
                                response: resp
                            });
                        }else{
                            console.log(this.item);

                            // Trigger upload error event
                            self.$uploader.trigger('upload-error', this.item);
                        }

                        // Clear files input value
                        self.$fileInput.val('');
                        self.$clone = self.$fileInput.clone();
                        self.$fileInput.replaceWith(self.$clone);
                        self.$fileInput = self.$clone;
                        self.$fileInput.bind('change', fileChange);
                    };

                    // Send the data
                    reqArray[file_id].setRequestHeader("X_FILENAME", files[i].name);
                    reqArray[file_id].send(data);
                });
            }

            // ------------------------------------
            // Fire init event
            // ------------------------------------
            $(function(){
                self.$uploader.trigger('init');
            });
        };

        return this.each(uploaderInit);
    };

    // Init Uploaders
    if($('.uploader').length > 0) $('.uploader').uploader();

})(jQuery);